import React from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";
import { graphql } from "gatsby"
import DietLayout from "../components/DietLayout";


export const query = graphql`
  {
    file(name: {eq: "diet1"}) {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

var planWegeFish = {
  title: "Wege + Ryby",
  description: "Dieta, która została stworzona na zasadach diety wegetariańskiej poszerzonej o grupę produktów jakimi są ryby. Jest to cenne źródło wielonienasyconych kwasów tłuszczowych, a także pełnowartościowego białka. Jadłospis jest skomponowany w taki sposób, by sprostać oczekiwaniom naszych najbardziej wymagających klientów!",
  menu: [
    {
      name: "Śniadanie",
      hour: "7:00",
      description: "Bananowe placuszki-serniczki z syropem klonowym",
    },
    {
      name: "Drugie Śniadanie",
      hour: "10:00",
      description: "Sałatka z melonem i selerem naciowym",
    },
    {
      name: "Obiad",
      hour: "13:00",
      description: "Ryba zapiekana w sosie koperkowym i sałatką",
    },
    {
      name: "Podwieczorek",
      hour: "17:00",
      description: "Zupa dyniowa z suszonym jabłkiem",
    },
    {
      name: "Kolacja",
      hour: "19:30",
      description: "Kotleciki z ricottą z warzywami",
    }
  ]    
}

const PlanWegeFishPage = ({ data }) => {
  console.log(data);
  return (
    <Layout>
      <Seo title="Diata Wege + Ryby" />
      <SmallHeroSection />
      <DecorativeSection />

      <DietLayout 
        diet={planWegeFish}
        image={data.file}
       />
    </Layout>
  )
}

export default PlanWegeFishPage
